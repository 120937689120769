import React from "react";
import { Bar } from "react-chartjs-2";

interface MonthlyFlightsChartProps {
  flightData: { labels: string[]; values: number[] };
}

export const MonthlyFlightsChart: React.FC<MonthlyFlightsChartProps> = ({ flightData }) => {
  const { labels, values } = flightData;

  return (
    <Bar
      data={{
        labels,
        datasets: [
          {
            label: "Number of Flights",
            data: values,
            backgroundColor: "blue",
            borderWidth: 1,
          },
        ],
      }}
      options={{
        plugins: {
          legend: { display: true },
        },
        scales: {
          x: { ticks: { autoSkip: false } },
          y: { beginAtZero: true },
        },
      }}
    />
  );
};

export default MonthlyFlightsChart;
