import { useEffect, useState } from "react";
import { Flight, EventData } from "../../api/models";
import { Bar } from "react-chartjs-2";

export const CityPairChart = ({ events, flights }: { events: EventData[]; flights: Flight[] }) => {
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    const cityPairCounts = events.reduce((acc, event) => {
      const flight = flights.find((f) => f.id === event.flight_id);
      if (!flight) return acc;

      const cityPair = `${flight.start_location} - ${flight.end_location}`;
      acc[cityPair] = acc[cityPair] || {};
      acc[cityPair][event.level] = (acc[cityPair][event.level] || 0) + 1;
      return acc;
    }, {} as Record<string, Record<number, number>>);

    const topCityPairs = Object.entries(cityPairCounts)
      .sort(([, levelsA], [, levelsB]) =>
        Object.values(levelsB).reduce((sum, val) => sum + val, 0) -
        Object.values(levelsA).reduce((sum, val) => sum + val, 0)
      )
      .slice(0, 20);

    const cityPairLabels = topCityPairs.map(([pair]) => pair);
    const levels = [...new Set(events.map((event) => event.level))];

    const datasets = levels.map((level) => ({
      label: `Level ${level}`,
      data: cityPairLabels.map((pair) => cityPairCounts[pair]?.[level] || 0),
      backgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`,
    }));

    setChartData({
      labels: cityPairLabels,
      datasets,
    });
  }, [events, flights]);

  if (!chartData) return <div>Loading...</div>;

  return (
    <Bar
      data={chartData}
      options={{
        indexAxis: "y",
        plugins: {
          title: { display: true, text: "Top 20 Event Counts by City Pair" },
          legend: { position: "top" },
        },
        responsive: true,
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        },
      }}
    />
  );
};

export default CityPairChart;
  