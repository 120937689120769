// src/index.tsx
import React from "react";
import ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraProvider,ColorModeScript } from "@chakra-ui/react";
import theme from './theme';
import { initializeIcons } from "@fluentui/react";
import "./index.css";
import 'react-datepicker/dist/react-datepicker.css';
import 'mapbox-gl/dist/mapbox-gl.css';

initializeIcons();
const rootElement = document.getElementById('root');
if (!rootElement) throw new Error("Failed to find the root element");

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    {/* Inject the ColorModeScript to handle color mode changes */}
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />

    {/* Apply ChakraProvider with the custom theme */}
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
