import { useEffect, useState } from "react";
import { EventData, Flight } from "../../api/models";
import { Bar } from "react-chartjs-2";

export const DestinationAirportChart = ({
  events,
  flights,
}: {
  events: EventData[];
  flights: Flight[];
}) => {
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    // Filter events for levels > 0
    const validEvents = events.filter((event) => event.level > 0);

    // Group by destination airport (from Flight) and event level
    const groupedData = validEvents.reduce((acc, event) => {
      const flight = flights.find((f) => f.id === event.flight_id);
      if (!flight) return acc;

      const destination = flight.end_location || "Unknown";
      acc[destination] = acc[destination] || {};
      acc[destination][event.level] = (acc[destination][event.level] || 0) + 1;
      return acc;
    }, {} as Record<string, Record<number, number>>);

    // Get unique destinations and levels
    const destinations = Object.keys(groupedData);
    const levels = [...new Set(validEvents.map((event) => event.level))];

    // Prepare datasets
    const datasets = levels.map((level) => ({
      label: `Level ${level}`,
      data: destinations.map((dest) => groupedData[dest][level] || 0),
      backgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`,
    }));

    setChartData({
      labels: destinations,
      datasets,
    });
  }, [events, flights]);

  if (!chartData) return <div>Loading...</div>;

  return (
    <Bar
      data={chartData}
      options={{
        plugins: {
          title: { display: true, text: "Event Count by Destination Airport" },
          legend: { position: "top" },
        },
        responsive: true,
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        },
      }}
    />
  );
};

export default DestinationAirportChart;



