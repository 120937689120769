import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Chart } from "react-chartjs-2";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

interface ASRIChartProps {
  asriData: { labels: string[]; asri: number[]; flights: number[] };
}

export const ASRIChart: React.FC<ASRIChartProps> = ({ asriData }) => {
  const { labels, asri } = asriData;

  // Define the bar colors based on ASRI values
  const barColors = asri.map((value) =>
    value <= 5 ? "green" : value <= 12 ? "yellow" : "red"
  );

  // Calculate smoothed ASRI using rolling average
  const smoothedAsri = asri.map((_, index) => {
    const slice = asri.slice(Math.max(0, index - 2), index + 1); // Take up to 3 values
    const average = slice.reduce((sum, value) => sum + value, 0) / slice.length;
    return average;
  });

  return (
    <Chart
      type="bar"
      data={{
        labels,
        datasets: [
          {
            type: "bar",
            label: "Average Safety Risk Index (ASRI)",
            data: asri,
            backgroundColor: barColors,
          },
          {
            type: "line",
            label: "Smoothed ASRI",
            data: smoothedAsri,
            borderColor: "blue",
            borderWidth: 2,
            tension: 0.3,
            fill: false,
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: true,
          },
        },
        scales: {
          x: { ticks: { autoSkip: false } },
          y: { beginAtZero: true },
        },
      }}
    />
  );
};

export default ASRIChart;

