import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { EventData } from "../../api/models";

export const DeviationDistributionChart = ({
  events,
}: {
  events: EventData[];
}) => {
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    // Count Deviation Categories
    const categoryCounts = events.reduce((acc, event) => {
      const category = event.deviation_category || "Unknown";
      acc[category] = (acc[category] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    // Calculate Percentages and Combine Small Categories into "Other"
    const total = Object.values(categoryCounts).reduce((sum, count) => sum + count, 0);
    const sortedCategories = Object.entries(categoryCounts).sort((a, b) => b[1] - a[1]);

    const labels: string[] = [];
    const data: number[] = [];
    let otherCount = 0;

    sortedCategories.forEach(([category, count]) => {
      const percentage = (count / total) * 100;
      if (percentage < 1.8) {
        otherCount += count;
      } else {
        labels.push(category);
        data.push(count);
      }
    });

    if (otherCount > 0) {
      labels.push("Other");
      data.push(otherCount);
    }

    setChartData({
      labels,
      datasets: [
        {
          data,
          backgroundColor: labels.map(
            () =>
              `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`
          ),
        },
      ],
    });
  }, [events]);

  if (!chartData) return <div>Loading...</div>;

  return (
    <Pie
      data={chartData}
      options={{
        plugins: {
          title: { display: true, text: "Distribution of Deviations by Category" },
          legend: { position: "right" },
        },
      }}
    />
  );
};

export default DeviationDistributionChart;

