const BACKEND_URI = "";

// Importing necessary modules from your `authConfig.ts`
import { Config, SimpleAPIResponse,Flight,EventData,RiskData } from "./models";
import { useLogin, getToken, } from "../authConfig";

/**
 * Get the request headers, either with Bearer token from MSAL or headers injected by Azure Container Apps.
 * @param idToken - Token for authentication (if using MSAL).
 */
export async function getHeaders(idToken?: string | undefined): Promise<Record<string, string>> {
    const headers: Record<string, string> = {};

    // If using MSAL login and there's an ID token, include the Authorization header
    if (useLogin && idToken) {
        headers["Authorization"] = `Bearer ${idToken}`;
    }

    // Return headers (empty if not using MSAL or no token available)
   return headers;
    
}


/**
 * Fetches the configuration from the backend.
 */
export async function configApi(): Promise<Config> {
    const response = await fetch(`${BACKEND_URI}/config`, {
        method: "GET"
    });

    if (!response.ok) {
        throw new Error(`Fetching config failed: ${response.statusText}`);
    }

    return (await response.json()) as Config;
}


export async function uploadFileApi(request: FormData, idToken: string): Promise<SimpleAPIResponse> {
    const response = await fetch("/upload", {
        method: "POST",
        headers: await getHeaders(idToken),
        body: request
    });

    if (!response.ok) {
        throw new Error(`Uploading files failed: ${response.statusText}`);
    }

    const dataResponse: SimpleAPIResponse = await response.json();
    return dataResponse;
}


export async function deleteUploadedFileApi(filename: string, idToken: string): Promise<SimpleAPIResponse> {
    const headers = await getHeaders(idToken);
    const response = await fetch("/delete_uploaded", {
        method: "POST",
        headers: { ...headers, "Content-Type": "application/json" },
        body: JSON.stringify({ filename })
    });

    if (!response.ok) {
        throw new Error(`Deleting file failed: ${response.statusText}`);
    }

    const dataResponse: SimpleAPIResponse = await response.json();
    return dataResponse;
}

export async function listUploadedFilesApi(idToken: string): Promise<string[]> {
    const headers = await getHeaders(idToken);  // Ensure idToken is not undefined or null
    console.log("Headers: ", headers);  // Debugging: Check headers
    
    const response = await fetch(`/list_uploaded`, {
        method: "GET",
        headers: headers
    });

    if (!response.ok) {
        throw new Error(`Listing files failed: ${response.statusText}`);
    }

    const dataResponse: string[] = await response.json();
    return dataResponse;
}

/**
 * Fetches the flight data from the backend.
 */
export async function fetchFlightData(idToken: string): Promise<Flight[]> {
    const headers = await getHeaders(idToken);  // Get the headers (with authorization if needed)

    const response = await fetch("/flights", {
        method: "GET",
        headers: headers
    });

    if (!response.ok) {
        throw new Error(`Fetching flights failed: ${response.statusText}`);
    }

    const flightData: Flight[] = await response.json();
    return flightData;
}

/**
 * Fetches the event data for a specific flight from the backend.
 */
export async function fetchEventData(flightId: number, idToken: string): Promise<EventData[]> {
    const headers = await getHeaders(idToken);  // Get the headers (with authorization if needed)

    const response = await fetch(`/events?flight_id=${flightId}`, {
        method: "GET",
        headers: headers
    });

    if (!response.ok) {
        throw new Error(`Fetching events for flight ${flightId} failed: ${response.statusText}`);
    }

    const eventData: EventData[] = await response.json();
    console.log("Fetched event data:", eventData); 
    return eventData;
}



/**
 * Fetch the Power BI embed token from your FastAPI backend.
 * This function makes a request to the backend route that generates the token.
 * 
 * @returns {Promise<string>} - The embed token for Power BI report embedding.
 */
export const fetchEmbedToken = async (): Promise<string> => {
    try {
      const response = await fetch('/get-embed-token', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error fetching embed token: ${response.statusText}`);
      }
  
      const data = await response.json();
      return data.embed_token; // Assuming your FastAPI backend returns { embed_token: "..." }
    } catch (error) {
      console.error('Failed to fetch Power BI embed token:', error);
      throw error; // Re-throw the error to be handled by the calling code
    }
  };

  /**
 * Updates the FOQA status of a specific flight.
 * @param flightId - The ID of the flight whose FOQA status needs to be updated.
 * @param newStatus - The new FOQA status to be updated.
 * @param idToken - The authentication token for the request.
 * @returns {Promise<SimpleAPIResponse>} - Response from the API after updating the status.
 */
export const updateFoqaStatus = async (flightId: number, newStatus: string, idToken: string): Promise<SimpleAPIResponse> => {
    const headers = await getHeaders(idToken);  // Get headers with the Authorization token

    const response = await fetch(`/flights/${flightId}/foqa-status`, {
        method: "PUT",
        headers: {
            ...headers,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ foqa_status: newStatus }),  // Send the new FOQA status in the request body
    });

    if (!response.ok) {
        throw new Error(`Updating FOQA status failed: ${response.statusText}`);
    }

    const dataResponse: SimpleAPIResponse = await response.json();
    return dataResponse;
};

/**
 * Fetches the weather data (departure and arrival) for a specific flight from the backend.
 * @param flightId - The ID of the flight to fetch weather data for.
 * @param idToken - The authentication token for the request.
 * @returns {Promise<{ departure_weather: string; arrival_weather: string }>} - The weather data.
 */
export async function fetchWeatherData(flightId: number, idToken: string): Promise<{ departure_weather: string; arrival_weather: string }> {
    const headers = await getHeaders(idToken); // Get headers with the Authorization token

    const response = await fetch(`/weather/${flightId}`, {
        method: "GET",
        headers: headers,
    });

    if (!response.ok) {
        throw new Error(`Fetching weather data failed: ${response.statusText}`);
    }

    return await response.json();
}

/**
 * Fetches the risk data from the backend.
 * @param idToken - The authentication token for the request.
 * @returns {Promise<RiskData[]>} - The risk data.
 */
export async function fetchRiskData(idToken: string): Promise<RiskData[]> {
    const headers = await getHeaders(idToken); // Get headers with the Authorization token

    const response = await fetch("/risk", {
        method: "GET",
        headers: headers,
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch risk data: ${response.statusText}`);
    }

    return await response.json();
}

/**
 * Fetches the flight data from the backend.
 * @param idToken - The authentication token for the request.
 * @returns {Promise<Flight[]>} - The flight data.
 */
export async function fetchFlights(idToken: string): Promise<Flight[]> {
    const headers = await getHeaders(idToken); // Get headers with the Authorization token

    const response = await fetch("/flights", {
        method: "GET",
        headers: headers,
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch flights: ${response.statusText}`);
    }

    return await response.json();
}

/**
 * Fetches the event data for a specific flight from the backend.
 * @param flightId - The ID of the flight to fetch event data for.
 * @param idToken - The authentication token for the request.
 * @returns {Promise<EventData[]>} - The event data.
 */
export async function fetchEvents(flightId: number, idToken: string): Promise<EventData[]> {
    const headers = await getHeaders(idToken); // Get headers with the Authorization token

    const response = await fetch(`/events?flight_id=${flightId}`, {
        method: "GET",
        headers: headers,
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch events for flight ${flightId}: ${response.statusText}`);
    }

    return await response.json();
}








