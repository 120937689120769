import { useEffect, useState } from "react";
import { EventData, Flight } from "../../api/models";
import { Bar } from "react-chartjs-2";

export const EventsPerMonthChart = ({
  events,
  flights,
}: {
  events: EventData[];
  flights: Flight[];
}) => {
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    // Map events to their corresponding flight's start_date
    const enrichedEvents = events.map((event) => {
      const flight = flights.find((f) => f.id === event.flight_id);
      return { ...event, start_date: flight?.start_date };
    });

    // Group by Month-Year and Level
    const groupedData = enrichedEvents.reduce((acc, event) => {
      if (!event.start_date) return acc; // Skip events without a start_date
      const monthYear = new Date(event.start_date).toISOString().slice(0, 7); // Format YYYY-MM
      acc[monthYear] = acc[monthYear] || {};
      acc[monthYear][event.level] = (acc[monthYear][event.level] || 0) + 1;
      return acc;
    }, {} as Record<string, Record<number, number>>);

    const months = Object.keys(groupedData).sort();
    const levels = [...new Set(enrichedEvents.map((event) => event.level))];

    const datasets = levels.map((level) => ({
      label: `Level ${level}`,
      data: months.map((month) => groupedData[month][level] || 0),
      backgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`,
    }));

    setChartData({
      labels: months,
      datasets,
    });
  }, [events, flights]);

  if (!chartData) return <div>Loading...</div>;

  return (
    <Bar
      data={chartData}
      options={{
        plugins: {
          title: { display: true, text: "Events Per Month" },
          legend: { position: "top" },
        },
        responsive: true,
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        },
      }}
    />
  );
};

export default EventsPerMonthChart;

