// src/pages/dashboard.tsx
import React, { useEffect, useState } from "react";
import { fetchFlights, fetchRiskData,fetchEvents } from "../api";
import { Flight, RiskData,EventData } from "../api/models";
import { useMsal } from "@azure/msal-react"; // MSAL hook for authentication
import { getToken } from "../authConfig"; // Helper function for acquiring the token
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController, 
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Spinner, Box, Flex, Select,Grid } from "@chakra-ui/react";
import { DeviationDistributionChart } from "../components/charts/DeviationDistributionChart";
import { DestinationAirportChart } from "../components/charts/EventCountByDestinationAirport";
import { CityPairChart } from "../components/charts/Top20EventsbyCityPair";
import { ASRIChart } from "../components/charts/ASRIChart";
import MonthlyFlightsChart from "../components/charts/MonthlyFlightsChart";
import EventCountByPhaseChart from "../components/charts/EventCountByPhaseChart";
import EventsPerMonthChart from "../components/charts/EventsPerMonthChart";
import TopEventTypesChart from "../components/charts/TopEventTypesChart";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController, 
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard: React.FC = () => {
  const [flights, setFlights] = useState<Flight[]>([]);
  const [riskData, setRiskData] = useState<RiskData[]>([]);
  const [eventData, setEventData] = useState<EventData[]>([]);
  const [filteredRiskData, setFilteredRiskData] = useState<RiskData[]>([]);
  const [filteredFlights, setFilteredFlights] = useState<Flight[]>([]);
  
  const [asriData, setAsriData] = useState<{
    labels: string[];
    asri: number[];
    flights: number[];
  }>({ labels: [], asri: [], flights: [] });
  const [flightChartData, setFlightChartData] = useState<{ labels: string[]; values: number[] }>({
    labels: [],
    values: [],
  });
  const [loading, setLoading] = useState<boolean>(true);
  const { instance: client } = useMsal();
  const [error, setError] = useState<string | null>(null);

  const [selectedYear, setSelectedYear] = useState<string>("All");
  const [selectedMonth, setSelectedMonth] = useState<string>("All");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const idToken = await getToken(client); // Acquire the ID token using MSAL
        if (!idToken) {
          throw new Error("No authentication token available");
        }

        const flightsData = await fetchFlights(idToken); // Fetch flights with token
        const risksData = await fetchRiskData(idToken); // Fetch risks with token
        // Fetch events for each flight
        const eventsDataPromises = flightsData.map((flight) =>
          fetchEvents(flight.id, idToken).catch((err) => {
            console.error(`Failed to fetch events for flight ${flight.id}:`, err);
            return []; // Return an empty array if fetching fails for a specific flight
          })
        );

        const eventsData = (await Promise.all(eventsDataPromises)).flat(); // Flatten the array of arrays

        setFlights(flightsData);
        setRiskData(risksData);
        setEventData(eventsData);
        setFilteredFlights(flightsData); // Initialize filtered data
        setFilteredRiskData(risksData); // Initialize filtered data
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load dashboard data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [client]);

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const year = e.target.value;
    setSelectedYear(year);
    filterData(year, selectedMonth);
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const month = e.target.value;
    setSelectedMonth(month);
    filterData(selectedYear, month);
  };

  const filterData = (year: string, month: string) => {
    const yearFilter = year !== "All" ? Number(year) : null;
    const monthFilter = month !== "All" ? Number(month) : null;

    const filteredRisks = riskData.filter((risk) => {
      const matchesYear = yearFilter ? risk.year === yearFilter : true;
      const matchesMonth = monthFilter ? risk.month === monthFilter : true;
      return matchesYear && matchesMonth;
    });

    const filteredFlights = flights.filter((flight) => {
      const flightDate = new Date(flight.start_date);
      const matchesYear = yearFilter ? flightDate.getFullYear() === yearFilter : true;
      const matchesMonth = monthFilter ? flightDate.getMonth() + 1 === monthFilter : true;
      return matchesYear && matchesMonth;
    });

    setFilteredRiskData(filteredRisks);
    setFilteredFlights(filteredFlights);
  };

  // Derived data for charts
  useEffect(() => {
    // Aggregate Risk Data by Year-Month
  const aggregatedRiskData = filteredRiskData.reduce((acc, risk) => {
    const key = `${risk.year}-${String(risk.month).padStart(2, "0")}`;
    if (!acc[key]) acc[key] = { riskIndexSum: 0, count: 0 };
    acc[key].riskIndexSum += risk.risk_index;
    acc[key].count += 1;
    return acc;
  }, {} as Record<string, { riskIndexSum: number; count: number }>);

  const uniqueFlightsPerMonth = filteredFlights.reduce((acc, flight) => {
    const key = `${new Date(flight.start_date).getFullYear()}-${String(
      new Date(flight.start_date).getMonth() + 1
    ).padStart(2, "0")}`;
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  // Sort the keys (yyyy-mm) lexicographically
  const sortedKeys = Object.keys(aggregatedRiskData).sort();

  // Prepare sorted labels, ASRI, and flights data
  const labels: string[] = [];
  const asri: number[] = [];
  const flights: number[] = [];

  sortedKeys.forEach((key) => {
    labels.push(key);
    const avgRiskIndex = aggregatedRiskData[key].riskIndexSum / (uniqueFlightsPerMonth[key] || 1);
    asri.push(avgRiskIndex);
    flights.push(uniqueFlightsPerMonth[key] || 0);
  });

  setAsriData({ labels, asri, flights });

  // Prepare Monthly Flights Data
  setFlightChartData({ labels, values: flights });
}, [filteredRiskData, filteredFlights]);
  
    

if (loading) return <Spinner />;
if (error) return <Box color="red.500">{error}</Box>;

return (
  <Flex direction="column" p={4}>
    <Box mb={6}>
      <h1>Statistics Dashboard</h1>
    </Box>

    {/* Filters */}
    <Flex mb={4} justifyContent="space-between" alignItems="center">
      <Select placeholder="Filter by Year" value={selectedYear} onChange={handleYearChange}>
        <option value="All">All</option>
        {Array.from(new Set(riskData.map((risk) => risk.year)))
          .sort()
          .map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
      </Select>
      <Select placeholder="Filter by Month" value={selectedMonth} onChange={handleMonthChange}>
        <option value="All">All</option>
        {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
          <option key={month} value={month}>
            {new Date(0, month - 1).toLocaleString("default", { month: "long" })}
          </option>
        ))}
      </Select>
    </Flex>

    {/* Charts */}
    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
      <Box>
        <h2>Average Safety Risk Index (ASRI)</h2>
        <ASRIChart asriData={asriData} />
      </Box>
      <Box>
        <h2>Monthly Flights</h2>
        <MonthlyFlightsChart flightData={flightChartData} />
      </Box>
      <Box>
  <h2>Events by Destination Airport</h2>
  <DestinationAirportChart events={eventData} flights={flights} />
</Box>

      <Box>
        <h2>Top 20 Events by City Pair</h2>
        <CityPairChart events={eventData} flights={flights} />
      </Box>
      <Box>
  <h2>Event Count by Phase of Flight</h2>
  <EventCountByPhaseChart events={eventData} />
</Box>
<Box>
  <h2>Events Per Month</h2>
  <EventsPerMonthChart events={eventData} flights={flights} />
</Box>
<Box>
  <h2>Top 10 Event Counts by Event Type</h2>
  <TopEventTypesChart events={eventData} />
</Box>
<Box>
  <h2>Deviation Distribution</h2>
  <DeviationDistributionChart events={eventData} />
</Box>;
    </Grid>
  </Flex>
);
};

export default Dashboard;


