// src/pages/FlightsTable.tsx
import React, { useEffect, useState } from 'react';
import { fetchFlightData,fetchEventData,updateFoqaStatus,fetchWeatherData } from '../api';
import { Flight,EventData,WeatherData } from '../api/models';  // Import the Flight interface
import { useMsal } from "@azure/msal-react";  // MSAL hook for authentication
import { getToken } from '../authConfig';  // Get token function
import styles from '../styles/FlightsComponent.module.css'; 
import Sidebar from '../components/Sidebar';  // Sidebar for filtering
import { Flex, Box,Button,Spinner,Tab,Tabs,TabPanels,TabPanel,Select,Input, Textarea,TabList,useColorModeValue,ColorMode } from '@chakra-ui/react';
import Map, { Marker } from "react-map-gl"; // Import Map and Marker components


const FlightsTable: React.FC = () => {
  const [flights, setFlights] = useState<Flight[]>([]);  // Use the Flight type for state
  const [events, setEvents] = useState<EventData[] | null>(null); 
  const [selectedFlight, setSelectedFlight] = useState<Flight | null>(null);  
  const [error, setError] = useState<string | null>(null);
  const { instance: client } = useMsal();  // Get MSAL client instance
  const [isLoading, setIsLoading] = useState<boolean>(true);  // Loading state for fetching data
  const [isFetchingEvents, setIsFetchingEvents] = useState<boolean>(false); // Separate loading for events
  const [currentPage, setCurrentPage] = useState<number>(1);  // Track current page
  const [rowsPerPage, setRowsPerPage] = useState<number>(10); 
  const [weather, setWeather] = useState<WeatherData | null>(null);
  const [viewport, setViewport] = useState({
    latitude: 37.7749, // Default latitude
    longitude: -122.4194, // Default longitude
    zoom: 5, // Default zoom level
  });
  const bernCoordinates = { lat: 46.94809, lng: 7.44744 }; // Bern, Switzerland
  const newYorkCoordinates = { lat: 40.7128, lng: -74.006 }; // New York, USA
 // const [departureCoords, setDepartureCoords] = useState<{ lat: number; lng: number } | null>(null);
  //const [arrivalCoords, setArrivalCoords] = useState<{ lat: number; lng: number } | null>(null);
  const [departureCoords, setDepartureCoords] = useState<{ lat: number; lng: number }>(bernCoordinates);
  const [arrivalCoords, setArrivalCoords] = useState<{ lat: number; lng: number }>(newYorkCoordinates);


// Dynamically set background and text colors based on color mode
const tabBg = useColorModeValue('gray.800', '#333');
const selectedTabBg = useColorModeValue('#666666', '#000');
const textColor = useColorModeValue( 'black','white');
const tableBg = useColorModeValue('#f8f0f0', '#333');
const tableHeaderColor = useColorModeValue('black', 'white');

  useEffect(() => {
    // Dynamically adjust the number of rows per page based on window size
    const adjustRowsPerPage = () => {
      const tableHeight = window.innerHeight - 250; // Adjust according to other UI elements
      const rowHeight = 40; // Approximate row height, adjust as needed
      const rowsThatFit = Math.floor(tableHeight / rowHeight);
      setRowsPerPage(rowsThatFit);
    };

    // Adjust rowsPerPage on initial load and on window resize
    adjustRowsPerPage();
    window.addEventListener('resize', adjustRowsPerPage);

    // Fetch flight data
    const fetchFlights = async () => {
      try {
        const idToken = await getToken(client);  // Fetch token
        if (!idToken) {
          throw new Error("No authentication token available");
        }
        const flightData = await fetchFlightData(idToken);  // Call the new API function
        setFlights(flightData);
        setIsLoading(false);  // Stop loading once data is fetched
      } catch (err) {
        setError("Failed to load flight data");
        setIsLoading(false);  // Stop loading on error
      }
    };

    fetchFlights();

    return () => {
      window.removeEventListener('resize', adjustRowsPerPage);
    };
  }, [client]);

// Function to handle row click and fetch events for the selected flight
const handleRowClick = async (flight: Flight) => {
  console.log("Flight row clicked:", flight); 
  setSelectedFlight(flight);  // Set the clicked flight as the selected one
  setIsFetchingEvents(true);  // Show loading state for events
  setEvents(null);  // Reset events while fetching
  setWeather(null); // Reset weather while fetching
  //setDepartureCoords(null);
  //setArrivalCoords(null);

  try {
    const idToken = await getToken(client);
    if (!idToken) {
      throw new Error("No authentication token available");
    }
     // Fetch events for the selected flight
    const eventData = await fetchEventData(flight.id, idToken);  // Fetch events for the selected flight
    console.log("Event data received:", eventData);
    setEvents(eventData);

    // Fetch weather data for the selected flight
    const weatherData = await fetchWeatherData(flight.id, idToken);
    console.log("Weather data received:", weatherData);
    setWeather(weatherData);

    // Set coordinates
    // Update this logic when dynamic coordinates are available
    setDepartureCoords(bernCoordinates); // Temporary: Bern
    setArrivalCoords(newYorkCoordinates); // Temporary: New York
    //setDepartureCoords({ lat: flight.start_airport_latitude, lng: flight.start_airport_longitude });
    //setArrivalCoords({ lat: flight.end_airport_latitude, lng: flight.end_airport_longitude });


    setIsFetchingEvents(false);  // Stop loading state after fetching events
  } catch (err) {
    console.error("Failed to load event data:", err);
    setError("Failed to load event data");
    setIsFetchingEvents(false);
  }
};


  if (isLoading) return <div>Loading flight data...</div>;
  if (error) return <div>Error: {error}</div>;

  // Calculate the rows for the current page
  const indexOfLastFlight = currentPage * rowsPerPage;
  const indexOfFirstFlight = indexOfLastFlight - rowsPerPage;
  const currentFlights = flights.slice(indexOfFirstFlight, indexOfLastFlight);

  // Handle page change
  const handleNextPage = () => {
    if (currentPage < Math.ceil(flights.length / rowsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFoqaStatusChange = async (e: React.ChangeEvent<HTMLSelectElement>, flightId: number) => {
    const newStatus = e.target.value;
    try {
      const idToken = await getToken(client);  // Fetch the token using your MSAL integration
      if (!idToken) {
        throw new Error("No authentication token available");
      }
      
      // Update the status in the backend
      await updateFoqaStatus(flightId, newStatus, idToken);
      
      // Update the flight data locally
      setFlights((prevFlights) =>
        prevFlights.map((flight) =>
          flight.id === flightId ? { ...flight, foqa_status: newStatus } : flight
        )
      );
      
      // Optionally, update selected flight's status
      setSelectedFlight((prevFlight) => prevFlight ? { ...prevFlight, foqa_status: newStatus } : null);
      
    } catch (error) {
      console.error("Failed to update FOQA status", error);
    }
  };
  

  // Function to get the background color based on the FOQA status
  const getStatusColor = (status: string) => {
    switch (status) {
      case "complete":
        return "green"; // Background color for 'complete'
      case "open":
        return "grey"; // Background color for 'open'
      case "pilot":
        return "blue"; // Background color for 'pilot'
      default:
        return "transparent"; // Default or unknown status
    }
  };


  
  return (
    <Flex direction="column" height="100vh">
      {/* Flights Table Section in the upper half */}
      <Flex flex="1" direction="row" height={selectedFlight ? "50%" : "100%"}>
        <Box width="16.66%">
          <Sidebar />
        </Box>
  
        <Box flex="1" p={4} position="relative">
        <div className={styles.tableContainer} style={{ maxHeight: "100%", overflowY: "auto" }}>
          <table className={styles.table} style={{ backgroundColor: tableBg }}>
              <thead className={styles.stickyHeader}>
                <tr>
                <th className={styles.th} style={{ color: textColor }}>Departure<br />Date/Time (UTC)</th>
                <th className={styles.th} style={{ color: textColor }}>Departure<br />Airport</th>
                <th className={styles.th} style={{ color: textColor }}>Arrival<br />Date/Time (UTC)</th>
                <th className={styles.th} style={{ color: textColor }}>Arrival<br />Airport</th>
                <th className={styles.th} style={{ color: textColor }}>Fleet</th>
                <th className={styles.th} style={{ color: textColor }}>Aircraft</th>
                <th className={styles.th} style={{ color: textColor }}>FOQA Status</th>
                <th className={styles.th} style={{ color: textColor }}>Events</th>
                </tr>
              </thead>
              <tbody>
                {currentFlights.map((flight) => (
                  <tr
                    key={flight.id}
                    onClick={() => handleRowClick(flight)}
                    className={selectedFlight?.id === flight.id ? styles.selectedRow : ""}
                  >
                    <td className={styles.td}>{new Date(flight.dep_datetime).toLocaleString()}</td>
                    <td className={styles.td}>{flight.start_location}</td>
                    <td className={styles.td}>{new Date(flight.arr_datetime).toLocaleString()}</td>
                    <td className={styles.td}>{flight.end_location}</td>
                    <td className={styles.td}>{flight.fleet}</td>
                    <td className={styles.td}>{flight.aircraft}</td>
                    <td
                    className={styles.td}
                    style={{
                      backgroundColor: getStatusColor(flight.foqa_status), // Dynamic background color
                      color: "white" // Adjust text color if needed
                    }}
                  >
                    {flight.foqa_status}
                  </td>
                    <td className={styles.td}></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Pagination controls, stick to the bottom right */}
          <Flex position="sticky" bottom="0" right="0" justifyContent="flex-end" p={4} >
            <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </Button>
            <Box mx={2}>Page {currentPage}</Box>
            <Button onClick={handleNextPage} disabled={currentPage === Math.ceil(flights.length / rowsPerPage)}>
              Next
            </Button>
          </Flex>
        </Box>
      </Flex>
       {/* Lower section: Tabs and Events Table, aligned with Flights Table */}
      {selectedFlight && (
        <Flex flex="1" direction="row" p={4} height="50%">
        {/* Empty box to match the sidebar's width */}
        <Box width="16.66%" height="100%">
          {/* This empty box ensures alignment with the sidebar */}
        </Box>
          {/* Tabs: Flight Info, Map, Weather */}
          <Box width="43%" p={4} overflowY="auto">
            <Tabs>
              <TabList>
              <Tab bg={tabBg} color={textColor} _selected={{ bg: selectedTabBg, color: textColor }}>Flight Info</Tab>
                <Tab bg={tabBg} color={textColor} _selected={{ bg: selectedTabBg, color: 'white' }}>Map</Tab>
                <Tab bg={tabBg} color={textColor} _selected={{ bg: selectedTabBg, color: 'white' }}>Weather</Tab>
              </TabList>
            <TabPanels>
              <TabPanel>
  <div className={styles.tableContainer}>
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={styles.th} colSpan={4}>FOQA Information</th> {/* Keep colSpan at 4 */}
        </tr>
      </thead>
      <tbody>
        {/* New Row with Flight Information */}
        <tr>
          <td className={styles.td}>
            <strong>Aircraft:</strong> {selectedFlight.aircraft}
          </td>
          <td className={styles.td}>
            <strong>Type:</strong> {selectedFlight.type}
          </td>
          <td className={styles.td}>
            <strong>Departure:</strong> {selectedFlight.departure_rwy}<br />
            {new Date(selectedFlight.dep_datetime).toLocaleString()}
          </td>
          <td className={styles.td}>
            <strong>Arrival:</strong> {selectedFlight.arrival_rwy}<br />
            {new Date(selectedFlight.arr_datetime).toLocaleString()}
          </td>
        </tr>
        {/* Report and Animation in the same row */}
        <tr>
          <td className={styles.td}>
            <strong>Report</strong>
          </td>
          <td className={styles.td}>
            <Button colorScheme="blue" size="sm">Report</Button>
          </td>
          <td className={styles.td}>
            <strong>Animation</strong>
          </td>
          <td className={styles.td}>
            <Button colorScheme="blue" size="sm">Request Animation</Button>
          </td>
        </tr>
        <tr>
          <th className={styles.th} colSpan={4}>FOQA</th> {/* Keep colSpan at 4 */}
        </tr>
        {/* FOQA Status and Report Visibility in the same row */}
        <tr>
        <td className={styles.td}>
            <strong>FOQA Status</strong>
          </td>
          <td className={styles.td}>
            <Select
              className={styles.select}
              placeholder="Select FOQA Status"
              size="sm"
              value={selectedFlight?.foqa_status || ""}
              onChange={(e) => handleFoqaStatusChange(e, selectedFlight?.id || 0)}
            >
              <option value="complete">🟢 FOQA Complete</option>
              <option value="open">⚪ Open-Review Pending</option>
              <option value="pilot">🔵 Pilot Review Pending</option>
            </Select>
          </td>
          <td className={styles.td}>
            <strong>FOQA Report Visibility</strong>
          </td>
          <td className={styles.td}>
            <Select className={styles.select} placeholder="Select Visibility" size="sm">
              <option value="default">📋 System Default</option>
              <option value="safety">➕ Significant Flight (Include in Safety Report)</option>
            </Select>
          </td>
        </tr>
        {/* FOQA Comment */}
        <tr>
          <td className={styles.td} colSpan={4}>
            <strong>FOQA Comment</strong>
            <Textarea className={styles.textarea} placeholder="Set FOQA Comment" size="sm" />
          </td>
        </tr>
        {/* Flight ID */}
        <tr>
          <td className={styles.td} colSpan={4}>
            <strong>Flight ID:</strong> {selectedFlight.id}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</TabPanel>
              {/* Map Tab */}
              <TabPanel>
              {selectedFlight && departureCoords && arrivalCoords ? (
        <Map
          mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
          initialViewState={{
            latitude: (departureCoords.lat + arrivalCoords.lat) / 2,
            longitude: (departureCoords.lng + arrivalCoords.lng) / 2,
            zoom: 5,
          }}
          style={{ width: "100%", height: "400px" }}
          mapStyle="mapbox://styles/mapbox/streets-v11"
        >
          {/* Departure Marker */}
          <Marker
            latitude={departureCoords.lat}
            longitude={departureCoords.lng}
            color="blue"
          >
            <div>Departure</div>
          </Marker>

          {/* Arrival Marker */}
          <Marker
            latitude={arrivalCoords.lat}
            longitude={arrivalCoords.lng}
            color="red"
          >
            <div>Arrival</div>
          </Marker>
        </Map>
      ) : (
        <p style={{ color: "white" }}>Select a flight to view its route on the map.</p>
      )}
          </TabPanel>

          {/* Weather Tab */}
          <TabPanel>
          {weather ? (
              <div>
                <h3 style={{ color: "white" }}>Departure Weather</h3>
                <p style={{ color: "white" }}>{weather.departure_weather || "No weather data available"}</p>
                <h3 style={{ color: "white" }}>Arrival Weather</h3>
                <p style={{ color: "white" }}>{weather.arrival_weather || "No weather data available"}</p>
              </div>
            ) : (
              <p style={{ color: "white" }}>No weather data available.</p>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
          {/* Lower section: Events Table */}
          <Box width="44%"  p={4} borderRadius="md" overflowY="auto">
            <h2 style={{ color: "white" }}>Events for Flight: {selectedFlight.aircraft}</h2>
            {isFetchingEvents ? (
              <Spinner color="white" />
            ) : (
              events && (
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th color={textColor}>Event Time</th>
                      <th color={textColor}>Event ID</th>
                      <th color={textColor}>Deviation</th>
                      <th color={textColor}>Level</th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.map((event) => (
                      <tr key={event.event_id}>
                        <td className={styles.td}>{new Date(event.time).toLocaleString()}</td>
                        <td className={styles.td}>{event.event_id}</td>
                        <td className={styles.td}>{event.deviation}</td>
                        <td className={styles.td}>{event.level}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
            )}
          </Box>
        </Flex>
      )}
    </Flex>
  );
};


export default FlightsTable;