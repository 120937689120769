// src/components/charts/EventCountByPhaseChart.tsx
import { useEffect, useState } from "react";
import { EventData } from "../../api/models";
import { Bar } from "react-chartjs-2";

export const EventCountByPhaseChart = ({ events }: { events: EventData[] }) => {
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    // Filter and group by Phase of Flight and Level
    const filteredData = events.filter((event) => event.phase && event.level > 0);

    const groupedData = filteredData.reduce((acc, event) => {
      const phase = event.phase || "Unknown";
      acc[phase] = acc[phase] || {};
      acc[phase][event.level] = (acc[phase][event.level] || 0) + 1;
      return acc;
    }, {} as Record<string, Record<number, number>>);

    const phases = Object.keys(groupedData);
    const levels = [...new Set(filteredData.map((event) => event.level))];

    const datasets = levels.map((level) => ({
      label: `Level ${level}`,
      data: phases.map((phase) => groupedData[phase][level] || 0),
      backgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`,
    }));

    setChartData({
      labels: phases,
      datasets,
    });
  }, [events]);

  if (!chartData) return <div>Loading...</div>;

  return (
    <Bar
      data={chartData}
      options={{
        plugins: {
          title: { display: true, text: "Event Count by Phase of Flight" },
          legend: { position: "top" },
        },
        responsive: true,
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        },
      }}
    />
  );
};

export default EventCountByPhaseChart;
