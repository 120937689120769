// src/components/charts/TopEventTypesChart.tsx
import { useEffect, useState } from "react";
import { EventData } from "../../api/models";
import { Bar } from "react-chartjs-2";

export const TopEventTypesChart = ({ events }: { events: EventData[] }) => {
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    const groupedData = events.reduce((acc, event) => {
      const type = event.deviation_category || "Unknown";
      acc[type] = acc[type] || {};
      acc[type][event.level] = (acc[type][event.level] || 0) + 1;
      return acc;
    }, {} as Record<string, Record<number, number>>);

    // Get top 10 event types by total events
    const topTypes = Object.entries(groupedData)
      .map(([type, levels]) => ({
        type,
        total: Object.values(levels).reduce((sum, val) => sum + val, 0),
      }))
      .sort((a, b) => b.total - a.total)
      .slice(0, 10)
      .map((entry) => entry.type);

    const datasets = [...new Set(events.map((event) => event.level))].map((level) => ({
      label: `Level ${level}`,
      data: topTypes.map((type) => groupedData[type]?.[level] || 0),
      backgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`,
    }));

    setChartData({
      labels: topTypes,
      datasets,
    });
  }, [events]);

  if (!chartData) return <div>Loading...</div>;

  return (
    <Bar
      data={chartData}
      options={{
        indexAxis: "y",
        plugins: {
          title: { display: true, text: "Top 10 Event Counts by Event Type" },
          legend: { position: "top" },
        },
        responsive: true,
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        },
      }}
    />
  );
};

export default TopEventTypesChart;
